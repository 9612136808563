.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    z-index: 1000;
  }
  
  .footer-icons {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  .footer-item {
    text-align: center;
    flex: 1;
  }
  
  .footer-item a {
    text-decoration: none;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-item a p {
    margin: 5px 0 0;
    font-size: 12px;
    color: #555;
  }
  
  .footer-item a svg {
    font-size: 24px;
    color: #0e5eaa;
    padding: 5px;
    box-sizing: content-box;
  }
  
 
 
  .footer-item a.active p {
    color: #0e5eaa;
  }
  
  .footer-item a:hover svg,
  .footer-item a:hover p {
    color: #0e5eaa;
  }
  