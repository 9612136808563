.search-container {
  display: flex;
  justify-content: center;
  margin: 10px;
  gap:10px;
}

.suggestions-container {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
}

.search-input-container {
  flex-grow: 1;
  position: relative;
}

.search-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: start;
}

.search-button {
  padding: 10px 0px;
  font-size: 20px;
  background-color: white;
  color: #0e5eaa;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.header {
  text-align: center !important;
  height: 6rem !important;
}

.logo-home {
  width: 40px !important;
  object-fit: cover;
}
  .custom-input{
    padding:0.5rem;
  }

.buttons {
  display: flex;
  margin: 8px;
  justify-content: flex-end;
}

.icon-color{
  color: #0e5eaa !important;
}

.btn-new-top {
  padding:5px 10px;
  border: none;
  color: #fff;
  background-color: #0e5eaa;
  border-radius: 5px;
}

.btn-new-top-filters{
  color:#0e5eaa;
  padding:10px 20px;
  height: 2.5rem;
  background-color: #fff;
  border:1px solid #0e5eaa;
  border-radius: 5px;
}

.sort-by-options {
  padding: 1rem;
 
}

.sort-option-button {
  padding: 0.5rem;
  margin: 0.5rem 0rem;
  border:none;
  border-radius: 5px;
}

.sort-option-button:hover {
  background-color: #f0f0f0;
}

.sort-option-button.active {
  background-color: #0e5eaa;
  color: white;
  border-color: #0e5eaa;
}
.filters-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.vertical-tabs {
  width: 30%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.tab-item {
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-bottom: 1.5rem;
  background-color: #eaeaea;
  border-radius: 4px;
  text-align: center;
}

.tab-item.active {
  background-color: #0e5eaa;
  color: white;
  font-weight: bold;
}

.tab-content {
  width: 70%;
  padding: 1rem;
  border-left: 1px solid #ccc;
}

.filter-actions {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.filters {
  padding: 0.5rem;
  margin: 1rem;
  color: #0e5eaa;
  margin-bottom: 10rem;
}

.filter-div {
  border: 2px solid #eeeeee;
}

.dropdown-heading {
  display: flex;
  justify-content: center;
  padding: 5px;
  margin: 0px;
}

.arrow-icon {
  margin: 5px;
  padding: 5px;
  background: white;
  color: #0e5eaa;
}

.options-filters {
  display: flex;
  flex-direction: column;
  gap:1rem;
  padding-top: 1rem;
}

.options-filters-sets {
  display: flex;
  justify-content: center;
}

.price-filter {
  padding: 1rem;
}

.select-options {
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  width: -webkit-fill-available;
}

.horizontal-slider {
  height: 10px;
  background: #e1f5fe;
  border-radius: 5px;
}

.thumb {
  height: 20px;
  width: 20px;
  background: #0e5eaa;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  top: -5px;
}

.price-filter{
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-top: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.apply-reset-button {
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  color: white;
  background-color: #0e5eaa;
  border: none;
  font-size: larger;
  border-radius: 10px;
}

.filter-buttons {
  justify-content: center;
  display: flex;
}

.filter-option {
  padding: 15px;

  border: 1px solid lightgray;
  background-color: #fff;
  color: black;
}

.filter-option.active {
  background-color: #0e5eaa;
  color: white;
}

.scrollable-filter{
  height: 400px;
  overflow: scroll;
}

.hidden-checkbox {
  display: none;
}

.hidden-checkbox:checked + .checkbox-label {
  background-color: #0e5eaa;
  color: white;
}


.hidden-checkbox:checked + label {
  background-color: #0e5eaa;
  color: white;
}



.hidden-checkbox:checked + .checkbox-label img {
  filter: invert();
}


.saved-filters-container {
  padding: 1rem;
  margin: 1rem;
  background: #fff;
  border: 2px solid #0e5eaa;
}

.saved-filters {
  padding: 0.5rem;
  margin: 1rem;
  background: #fff;
  border: 2px solid #adb5bd;
}

.saved-filter-details {
  background: rgba(0, 0, 0, 0.125);
  margin-bottom: 0px;
  padding: 0.5rem;
  font-weight: bolder;
  font-size: 1.5rem;
  color: #0e5eaa;
}

.saved-filter-btn {
  margin: 0.5rem;
  padding: 0.2rem 3rem;
  background: #0e5eaa;
  color: white;
  border: none;
}

.btns.disabled {
  /* Styles for the disabled button */
  background-color: rgba(128, 128, 128, 0.5);
  color: white;
}

.no-filter {
  padding: 1rem;
  margin: 1rem;
  background: rgba(0, 0, 0, 0.125);
  font-size: larger;
}

.error-img {
  width: 100%;
  margin-bottom: 2rem;
}

.no-property-found {
  padding: 1rem;
}

.checkbox-label {
  display: flex;
  padding: 0.5rem;
  border: 1px solid grey;
  width: fit-content;
  margin: 0.5rem;
  border-radius: 10px;
}

.suggestion-box {
  z-index: 10000000;
  position: relative;
}

.slick-prev{
z-index: 1;
left:10px !important;
}
.slick-next{
  z-index: 1;
  right:10px !important;
}

/* Toggle Container */
.toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Arial, sans-serif;
}

/* Hide default checkbox */
.toggle-container input[type="checkbox"] {
  display: none;
}

/* Toggle Label (Slider) */
.toggle-label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  background: #ccc;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

/* Toggle Slider */
.toggle-slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

/* Toggle Checked State */
input[type="checkbox"]:checked + .toggle-label {
  background: #4caf50;
}

input[type="checkbox"]:checked + .toggle-label .toggle-slider {
  transform: translateX(26px);
}

/* Toggle Text (Sold) */
.toggle-text {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

/* Dropdown Styling */
.filter-dropdown {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f9f9f9;
  color: #333;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  cursor: pointer;
}

/* Dropdown focus effect */
.filter-dropdown:focus {
  border-color: #4caf50;
}

/* Dropdown hover effect */
.filter-dropdown:hover {
  background: #f1f1f1;
}
