.date-picker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .date-picker-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .date-button {
    flex: 1;
    text-align: center;
  }
  
  .selected {
    background-color: #0055a5;
    color: white !important;
    font-weight: bold;
    border-radius: 50%;
    padding: 5px 7px;
  }
  
  .selected-date {
    background-color: #0055a5 !important;
    color: white !important;
  }
  


  .tour-option {
    padding: 20px;
    text-align: center;
  }
  
  .cancel-note {
    margin-top: 10px;
    font-size: 12px;
    color: gray;
  }
  
  .font-size-18 {
    font-size: 18px;
  }
  
  .time-picker-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .time-display {
    display: flex;
    align-items: center;
  }
  
  .time-column {
    display: flex;
    scroll-behavior: smooth;
  }
  
  .hour-selector {
   
    text-align: center;
  }
  
  .minute-selector {

    text-align: center;
  }
  
  .separator {
    margin: 0 10px; /* Adjust spacing as needed */
    font-size: 2em;
  }
  
  .time-value {
    height: 40px; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
  }
  
  .selected {
    font-weight: bold;
  }
  .font-size-x-large{
    font-size: x-large;
  }
  .visible-hide-content{
    visibility: hidden;
  }
  
  .btn-direction:hover{
  background-color: lightgrey;
  }