.body2{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: white;
  font-family: Roboto,sans-serif;
}

#card {
  position: relative;
  width: 100%;
  height: 100vh;
  background: white;
  overflow-y: auto;
  overflow-x: hidden;
}

.rect {
  /* background-color: #333D79FF; */
  position: absolute;
  width: 50px;
  height: 50px;
  right: 0%;
  top: 45%;
}

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 45%;
}

.top-section {
  /* background: #333D79FF; */
  border-bottom-left-radius: 50px;
}

.bottom-section {

  border-top-right-radius: 50px;
  background: white;
  height: 100%;
}

.explore-button{
  background-color: #fff;

border-radius: 50px;
}

h1 {
  text-align: center;
  margin: 5%;
  margin-top:5rem;
}

.white-text {
  color: white;
}

.button-1 {
  width: 50%;
  height: 20%;
  font-family: Roboto;
  color: white;
  background: #5C6898;
  border-radius: 5%;
}

.img-style {
  height: 3rem;
  width: auto;
  position: relative;
  top: 3rem;
}

/* CORE STYLES */
:root {
  --primary-color: rgba(13, 110, 139, 0.75);
  --overlay-color: rgba(24, 39, 51, 0.85);
  --menu-speed: 0.75s;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.4;
}

.showcase {
  background: var(--primary-color);
  color: #fff;
  height: 100vh;
  position: relative;
}

.showcase:before {
  content: '';
  background: url('https://images.pexels.com/photos/533923/pexels-photo-533923.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260') no-repeat center center/cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.showcase .showcase-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.showcase h1 {
  font-size: 4rem;
}

.showcase p {
  font-size: 1.3rem;
}

.btn-home {
  display: inline-block;
  border: none;
  background: var(--primary-color);
  color: #fff;
  /* padding: 0.75rem 0.5rem; */
  margin-top: 1rem;
  transition: opacity 1s ease-in-out;
  text-decoration: none;

}

.btn-home:hover {
  background: #6c757d;
}

/* MENU STYLES */
.menu-wrap {
  position: absolute;
  top: 0.6rem;
  /* bottom: 70px; */
  right: 70px;
  z-index: 1;
}

.menu-wrap .toggler {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: 0;
}

.menu-wrap .hamburger {
  position: absolute;
  border-radius: 30px;
  top: 0;
  left: 0;
  z-index: 1;
  width: 60px;
  height: 60px;
  padding: 1rem;
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Hamburger Line */
.menu-wrap .hamburger>div {
  position: relative;
  flex: none;
  width: 100%;
  height: 2px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}

/* Hamburger Lines - Top & Bottom */
.menu-wrap .hamburger>div::before,
.menu-wrap .hamburger>div::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: -10px;
  width: 100%;
  height: 2px;
  background: inherit;
}

/* Moves Line Down */
.menu-wrap .hamburger>div::after {
  top: 10px;
}

/* Toggler Animation */
.menu-wrap .toggler:checked+.hamburger>div {
  transform: rotate(135deg);
}

/* Turns Lines Into X */
.menu-wrap .toggler:checked+.hamburger>div:before,
.menu-wrap .toggler:checked+.hamburger>div:after {
  top: 0;
  transform: rotate(90deg);
}

/* Rotate On Hover When Checked */
.menu-wrap .toggler:checked:hover+.hamburger>div {
  transform: rotate(225deg);
}

/* Show Menu */
.menu-wrap .toggler:checked~.menu {
  visibility: visible;
}

.menu-wrap .toggler:checked~.menu>div {
  transform: scale(1);
  transition-duration: var(--menu-speed);  
}

.menu-wrap .toggler:checked~.menu>div>div {
  opacity: 5;
  transition: opacity 0.4s ease 0.4s;
}

.menu-wrap .menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.781);
}

.menu-wrap .menu>div {
  background: rgba(22, 14, 14, 0.436);
  width: 100vh;
  height: 100vh;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 0.4s ease;
}

.menu-wrap .menu>div>div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.menu-wrap .menu>div>div>ul>li {
  list-style: none;
  color: #0c0606;
  font-size: 1.5rem;
  padding: 1rem;
}

.menu-wrap .menu>div>div>ul>li>a {
  color: white;
  text-decoration: none;
  transition: color 0.4s ease;
}


.demo-btn-new-top {
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 3rem;
  border: none;
  color: #fff;
  /* background-color: #333D79FF; */
  border-radius: 50px;
  box-shadow: inset 0 0 #d44207;
  transition: ease .5s;
  font-size: 1rem;
  outline: none;
  text-align: center;
  padding: 1px;
  /* margin-right: 2rem; */
  /* margin-left: 2rem; */
}

.button-section {
  margin-top: 7rem;
}


/* General styles */
.nav-item {
  list-style-type: none;
}

.menu-container {
  position: relative;
  display: inline-block;
}

.nav-link {
  
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #007bff;
}

.arrow {
  margin-left: 8px;
  transition: transform 0.3s ease;
}


/* Dropdown styles */
.dropdown-mobile {
  font-size:large;
  border: 1px solid #ddd;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.dropdown-link-mobile {
  display: block;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease;
}






/* .icon-class{
  padding-right: 12px;
}  */

.img-style2 {
  width: 100%;
}

.width-section1 {
  width: 40%;
}

.width-section2 {
  width: 60%;
}

.icon-color-home {
  color: white !important;
}
.container{
  --bs-gutter-x: 0rem!important;
}