.calculator-container {
    max-width: 500px;
    margin: 30px auto;
    padding: 20px;
    background-color: #0e5eaa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  
  .input-property {
    width: 100%;
    padding: 10px;
   
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 20px;
  }
  
  .results {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .results p {
    font-size: 18px;
    color: #333;
    margin: 10px 0;
  }
  
  .calculate-btn{
    padding: 10px;
    background-color: #1976D2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    border-radius: 0px 20px 20px 0px;
    right: 0px;
    top: 0;
    height: 2.75rem;
  }